import * as Sentry from "@sentry/gatsby"
import { window, exists } from "browser-monads"

Sentry.init({
  dsn: "https://b4b9b3ecc7c2427fb4ee2f39a74b3351@o203656.ingest.sentry.io/4505238983802880",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  environment:
    exists(window) && window.location.hostname === "nucle.ai"
      ? "production"
      : "development",
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})
