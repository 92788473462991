import { initializeApp } from "firebase/app"
import React, { useContext } from "react"
import { getAnalytics, logEvent as _logEvent } from "firebase/analytics"
import { window, exists } from "browser-monads"

export const getMeasurementId = () => {
  const measurementId =
    exists(window) && window.location.hostname !== "nucle.ai"
      ? "G-JJZJ21VFML" // Development
      : "G-TRY9R5T4D9" // Production
  console.log("Measurement ID:", measurementId)
  return measurementId
}
export const onClientEntry = () => {
  // console.log("Trying to start analytics")
  const firebaseConfig = {
    apiKey: "AIzaSyBlvk4u4SX8jne5kgkMs7IkVnB9aph24rY",
    authDomain: "nucleai-9e529.firebaseapp.com",
    projectId: "nucleai-9e529",
    storageBucket: "nucleai-9e529.appspot.com",
    messagingSenderId: "21591526419",
    appId: "1:21591526419:web:e3a1e3b1219dc0012283e6",
    measurementId: getMeasurementId(),
  }
  const app = initializeApp(firebaseConfig)
  const analytics = getAnalytics(app)
  // console.log("Analytics started.", app, analytics)
}

const AppContext = React.createContext(null)

export const AppContextProvider = AppContext.Provider

const useLogEvent = () => {
  const app = useContext(AppContext)

  const logEvent =
    (eventName, args = {}) =>
    () => {
      console.log("Event:", eventName, args)
      _logEvent(getAnalytics(), eventName, { ...args, app: app })
    }

  return logEvent
}

export default useLogEvent
