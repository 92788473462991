// Imports commented as they are incompatible with es6 imports in firebase, and not quite needed
// import "bootstrap/dist/js/bootstrap.min.js";
// import "@popperjs/core/dist/umd/popper.min.js";
const bootstrap = require("bootstrap")

// Add firebase
const analytics = require("./src/analytics")
const { exists, window } = require("browser-monads")
const { getMeasurementId } = require("./src/analytics")
exports.onClientEntry = () => {
  analytics.onClientEntry()

  window.dataLayer = window.dataLayer || []
  function gtag() {
    window.dataLayer.push(arguments)
  }
  gtag("js", new Date())

  gtag("config", getMeasurementId())
}
