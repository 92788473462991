exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-details-js": () => import("./../../../src/pages/blog-details.js" /* webpackChunkName: "component---src-pages-blog-details-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-home-2-js": () => import("./../../../src/pages/home-2.js" /* webpackChunkName: "component---src-pages-home-2-js" */),
  "component---src-pages-home-3-js": () => import("./../../../src/pages/home-3.js" /* webpackChunkName: "component---src-pages-home-3-js" */),
  "component---src-pages-home-4-js": () => import("./../../../src/pages/home-4.js" /* webpackChunkName: "component---src-pages-home-4-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mj-index-js": () => import("./../../../src/pages/mj/index.js" /* webpackChunkName: "component---src-pages-mj-index-js" */),
  "component---src-pages-mj-p-js": () => import("./../../../src/pages/mj/p.js" /* webpackChunkName: "component---src-pages-mj-p-js" */),
  "component---src-pages-mj-tokenizer-js": () => import("./../../../src/pages/mj/tokenizer.js" /* webpackChunkName: "component---src-pages-mj-tokenizer-js" */),
  "component---src-pages-sd-p-js": () => import("./../../../src/pages/sd/p.js" /* webpackChunkName: "component---src-pages-sd-p-js" */)
}

